import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPostImages, getPosts } from 'api/post';

const initialState = {
  images: [],
  posts: [],
  info: [],
  statusFetchImages: null,
  statusFetchPosts: null,
};

export const fetchImages = createAsyncThunk('post/postImages', async () => {
  const { data } = await getPostImages();
  return data;
});

export const fetchPosts = createAsyncThunk('post/postPosts', async postsData => {
  const { data } = await getPosts(postsData);
  return data;
});

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  extraReducers: {
    [fetchImages.pending]: state => {
      state.statusFetchImages = 'loading';
    },
    [fetchImages.fulfilled]: (state, action) => {
      state.images = action.payload;
      state.statusFetchImages = 'success';
    },
    [fetchImages.rejected]: state => {
      state.statusFetchImages = 'failed';
    },
    [fetchPosts.pending]: state => {
      state.statusFetchPosts = 'loading';
    },
    [fetchPosts.fulfilled]: (state, action) => {
      state.posts = action.payload.results;
      state.info = { count: action.payload.count, prev: action.payload.previous, next: action.payload.next };
      state.statusFetchPosts = 'success';
    },
    [fetchPosts.rejected]: state => {
      state.statusFetchPosts = 'failed';
    },
  },
});
export const results = state => state.posts;
export default postsSlice.reducer;
