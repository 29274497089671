import client from './client';

export const getPostImages = async page => {
  return await client(`/api/postImages`, {
    method: 'GET',
    params: { page: page },
  });
};

export const getPosts = async postsData => {
  return await client(`/api/posts/${postsData.id}`, {
    method: 'GET',
    params: { page: postsData.page },
  });
};
