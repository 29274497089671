import classNames from 'classnames';
import Icon from 'components/atoms/Icon';
import React from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

const ScrollButton = () => {
  const { isContrast } = useSelector(state => state.availabilitys);
  const { isScrolled } = useSelector(state => state.availabilitys);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Icon
      className={classNames(styles.toTopButton, isContrast && styles.contrastChange)}
      onClick={scrollToTop}
      style={{ display: isScrolled ? 'inline' : 'none' }}
      name="arrow circle up"></Icon>
  );
};

export default ScrollButton;
