import { Pagination as SemanticPagination } from 'semantic-ui-react';

import styles from './styles.module.scss';

const Pagination = ({ ...props }) => {
  return (
    <div className={styles.pagination}>
      <SemanticPagination {...props} />
    </div>
  );
};

export default Pagination;
