import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from 'views/HomePage/HomePage';
import Page from 'views/Page/Page';

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/matters" element={<Page id={1} path={'/matters'} name="SSPMK Sprawy służbowe" />} />
            <Route path="/retiree" element={<Page id={2} path={'/retiree'} name="SSPMK Dla emeryta" />} />
            <Route path="/health" element={<Page id={3} path={'/health'} name="SSPMK Zdrowie" />} />
            <Route path="/history" element={<Page id={5} path={'/history'} name="SSPMK Historia" />} />
            <Route path="/thisWas" element={<Page id={6} path={'/thisWas'} name="SSPMK To było" />} />
            <Route path="/announcments" element={<Page id={4} path={'/announcments'} name="SSPMK Komunikaty" />} />
            <Route path="/gone" element={<Page id={7} path={'/gone'} name="SSPMK Mortuis" />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
