import client from './client';

export const getToken = async data => {
  return await client('/api/token', {
    method: 'POST',
    data: data,
  });
};

export const getRefreshToken = async () => {
  return await client('/api/token/refresh', {
    method: 'GET',
  });
};
