import { configureStore } from '@reduxjs/toolkit';
import availabilitysReducer from 'slices/availability/availabilitySlice';
import credentialsReducer from 'slices/credentials/credentialsSlice';
import postsReducer from 'slices/posts/postsSlice';

const store = configureStore({
  reducer: {
    credentials: credentialsReducer,
    availabilitys: availabilitysReducer,
    posts: postsReducer,
  },
});

export default store;
