import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import styles from './styles.module.scss';

const Layout = ({ children, isHomepage }) => {
  const { isContrast } = useSelector(state => state.availabilitys);

  return (
    <div
      className={classNames(styles.wrapper, isHomepage && styles.homepageWrapper, isContrast && styles.contrastChange)}>
      <Header isHomepage={isHomepage} />
      <div className={classNames(styles.layoutContainer, isHomepage && styles.homepageLayoutContainer)}>
        <main className={styles.layoutWrapper}>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
