import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a

import classNames from 'classnames';
import Icon from 'components/atoms/Icon';
import Modal from 'components/atoms/Modal';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Gallery from 'react-photo-gallery';
import { Carousel } from 'react-responsive-carousel';

import ScrollButton from '../toTopBtn/topBtn';
import styles from './styles.module.scss';

const Article = ({ postData, fontSize }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const currentFontSize = localStorage.getItem('fontSize') ? localStorage.getItem('fontSize') : fontSize;
  const postCreatedFormat = () => {
    const currentData = new Date(postData.created);
    const month = currentData.getUTCMonth() + 1;
    const year = currentData.getUTCFullYear();
    return month <= 9 ? `0${month}-${year}r` : `${month}-${year}r`;
  };

  // eslint-disable-next-line no-unused-vars
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const renderCarousel = () => {
    if (postData?.images?.length > 0) {
      return (
        <Carousel selectedItem={currentImage}>
          {postData?.images?.map((image, index) => (
            <div key={new Date()}>
              <img src={image.image} alt={`Nr ${index}`} />
              <p className="legend">{image.description}</p>
            </div>
          ))}
        </Carousel>
      );
    }
  };
  return (
    <>
      <article
        className={classNames(
          styles.articleContainer,
          postData?.images?.length === 1 && styles.articleContainerSingleImage,
          postData?.images?.length === 0 && styles.articleContainerNoImage
        )}>
        <>
          <span className={styles.title}>
            <h1>
              <strong>{`${postData?.title}    `}</strong>
            </h1>
            <h4>
              <Icon size="small" name="calendar alternate outline"></Icon> {`${postCreatedFormat()} `}
            </h4>
          </span>
          {postData?.images?.length <= 1 ? (
            <div className={postData?.images?.length == 1 ? styles.articleWrapper : styles.noImage}>
              {postData?.images[0]?.image && (
                <div className={styles.galleryWrapper}>
                  <Gallery
                    onClick={openLightbox}
                    photos={[
                      {
                        src: postData.images[0].image,
                        width: 4,
                        height: 3,
                      },
                    ]}
                  />
                </div>
              )}
              <p
                className={classNames(
                  styles.smallFont,
                  currentFontSize === 'medium' && styles.mediumFont,
                  currentFontSize === 'big' && styles.bigFont
                )}>
                {`${postData?.text}`}
              </p>
            </div>
          ) : (
            <>
              <p
                className={classNames(
                  styles.smallFont,
                  currentFontSize === 'medium' && styles.mediumFont,
                  currentFontSize === 'big' && styles.bigFont
                )}>
                {postData?.text}
              </p>
              {postData?.images?.length > 1 && (
                <Gallery
                  onClick={openLightbox}
                  photos={postData?.images.map(image => ({
                    src: image.image,
                    width: 4,
                    height: 3,
                  }))}
                />
              )}
            </>
          )}
          <Helmet>
            <title>{postData?.title}</title>
            <meta name="description" content={postData?.text?.slice(0, 100)} />
          </Helmet>
          <ScrollButton />

          <Modal
            closeOnDimmerClick={false}
            onClose={() => setViewerIsOpen(false)}
            onOpen={() => setViewerIsOpen(true)}
            closeIcon={true}
            open={viewerIsOpen}>
            {renderCarousel()}
          </Modal>
        </>
      </article>
      <div className={styles.separator}></div>
    </>
  );
};

export default Article;
