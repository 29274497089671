import Pagination from 'components/atoms/Pagination';
import Layout from 'components/layout/Layout';
import Article from 'components/molecules/article/article';
import Availability from 'components/organisms/availability/Availability';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchImages, fetchPosts } from 'slices/posts/postsSlice';

import styles from './styles.module.scss';

const Page = ({ id, name, path }) => {
  const dispatch = useDispatch();
  const { posts, statusFetchPosts, statusFetchImages, images, info } = useSelector(state => state.posts);
  const [fontSize, setFontSize] = useState(null);
  const [postData, setPostData] = useState();
  const [page, setPage] = useState(1);

  const handleOrderChange = (e, { activePage }) => {
    setPage(activePage);
    dispatch(fetchPosts({ id: id, page: activePage }));
  };

  useEffect(() => {
    dispatch(fetchPosts({ id: id, page: 1 }));
    dispatch(fetchImages({ page: 1 }));
  }, [dispatch, id]);

  useEffect(() => {
    const array = [];
    posts.map(post => {
      const x = images.filter(image => image.post === post.id);
      array.push({ ...post, images: [...x] });
      setPostData(array);
    });
  }, [posts, images]);

  return (
    <>
      <Layout>
        <div className={styles.pageWrapper}>
          <Availability setFontSize={setFontSize} fontSize={fontSize} />
          <>
            {statusFetchPosts === 'success' && statusFetchImages === 'success' ? (
              posts.length > 0 ? (
                <div className={styles.paginationArticlesWrapper}>
                  {postData?.map((data, index) => (
                    <Article key={index + new Date()} postData={data} fontSize={fontSize} />
                  ))}
                  <Pagination
                    activePage={page}
                    totalPages={info.count ? Math.ceil(info.count / 5) : 0}
                    onPageChange={handleOrderChange}
                  />
                </div>
              ) : (
                <p className={styles.emptyPage}>Ta zakładka jest jeszcze pusta..</p>
              )
            ) : (
              <TailSpin
                height="60"
                width="60"
                radius="2"
                color="#eb5118"
                ariaLabel="three-dots-loading"
                wrapperClass="spinnerWrapper"
              />
            )}
            <Helmet>
              <title>{name}</title>
              <meta name="description" content={name} />
              <link rel="canonical" href={path} />
            </Helmet>
          </>
        </div>
      </Layout>
    </>
  );
};

export default Page;
