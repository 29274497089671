import classNames from 'classnames';
import Icon from 'components/atoms/Icon';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveScrolled } from 'slices/availability/availabilitySlice';

import logo from '../../../images/logo_3.jpg';
import styles from './styles.module.scss';

function Header({ isHomepage }) {
  const [activeItem, setActiveItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  useEffect(() => {
    if (location.pathname !== '/') setActiveItem(location.pathname.slice(1));
  }, [location.pathname]);

  const menuItems = {
    LOGO: 'logo',
    MATTERS: 'SPRAWY SŁUŻBOWE',
    RETRIEE: 'DLA EMERYTA',
    HEALTH: 'ZDROWIE',
    ANNOUNCMENTS: 'KOMUNIKATY',
    GONE: 'MORTUIS',
    HISTORY: 'HISTORIA',
    THISWAS: 'TO BYŁO',
    LOGIN: 'ZALOGUJ',
  };

  const toggleVisible = () => {
    if (scrollY < 150) {
      setIsScrolled(false);
      dispatch(saveScrolled(false));
    } else {
      setIsScrolled(true);
      dispatch(saveScrolled(true));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  const handleItemClick = e => {
    if (e) {
      const { innerText } = e.target;
      setActiveItem(innerText);
      switch (innerText) {
        case menuItems.MATTERS:
          navigate('/matters');
          setShowHeader(prev => !prev);
          break;
        case menuItems.RETRIEE:
          navigate('/retiree');
          setShowHeader(prev => !prev);
          break;
        case menuItems.HEALTH:
          navigate('/health');
          setShowHeader(prev => !prev);
          break;
        case menuItems.ANNOUNCMENTS:
          navigate('/announcments');
          setShowHeader(prev => !prev);
          break;
        case menuItems.HISTORY:
          navigate('/history');
          setShowHeader(prev => !prev);
          break;
        case menuItems.THISWAS:
          navigate('/thisWas');
          setShowHeader(prev => !prev);
          break;
        case menuItems.GONE:
          navigate('/gone');
          setShowHeader(prev => !prev);
          break;
        case menuItems.LOGIN:
          navigate('/login');
          setShowHeader(prev => !prev);
          break;
        case menuItems.ADMIN:
          setShowHeader(prev => !prev);
          navigate('/admin');
          break;
      }
    } else {
      navigate('/');
    }
  };

  const handleClickBurger = () => {
    setShowHeader(prev => !prev);
  };

  return (
    <div
      className={classNames(
        styles.headerContainer,
        isHomepage && styles.headerHomePage,
        isScrolled && styles.scrollActtive,
        showHeader && styles.headerContainerPhone
      )}>
      <div className={styles.headerWrapper}>
        <img className={styles.logo} onClick={() => handleItemClick()} src={logo} alt="logo" />
        <ul className={classNames(styles.rowHeader, showHeader && styles.colHeader)}>
          <li onClick={handleItemClick} className={activeItem == 'matters' ? styles.active : ''}>
            Sprawy służbowe
          </li>
          <li onClick={handleItemClick} className={activeItem == 'retiree' ? styles.active : ''}>
            Dla Emeryta
          </li>
          <li onClick={handleItemClick} className={activeItem == 'health' ? styles.active : ''}>
            Zdrowie
          </li>
          <li onClick={handleItemClick} className={activeItem == 'announcments' ? styles.active : ''}>
            Komunikaty
          </li>
          <li onClick={handleItemClick} className={activeItem == 'history' ? styles.active : ''}>
            Historia
          </li>
          <li onClick={handleItemClick} className={activeItem == 'thisWas' ? styles.active : ''}>
            To było
          </li>
          <li onClick={handleItemClick} className={activeItem == 'gone' ? styles.active : ''}>
            Mortuis
          </li>
        </ul>
      </div>
      {!isScrolled && (
        <Icon
          name={showHeader ? 'close' : 'bars'}
          onClick={handleClickBurger}
          size="big"
          className={styles.hamburgerBtn}
        />
      )}
    </div>
  );
}

export default Header;
