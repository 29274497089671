import classNames from 'classnames';
import Icon from 'components/atoms/Icon';
import Tooltip from 'components/atoms/Tooltip';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveContrast } from 'slices/availability/availabilitySlice';

import styles from './styles.module.scss';

const ContrastChanger = () => {
  const [contrast, setContrast] = useState(
    localStorage.getItem('contrast') === 0 || !localStorage.getItem('contrast') ? 0 : 1
  );
  const [tooltip, showTooltip] = useState(true);
  const dispatch = useDispatch();
  const handleClick = () => {
    setContrast(prev => !prev);
  };

  useEffect(() => {
    dispatch(saveContrast(contrast));
  }, [contrast]);

  return (
    <>
      {
        <div className={classNames(styles.contrastChangerWrapper)}>
          <button
            data-tip
            data-for="contrastData"
            className={classNames(contrast === 'light' && styles.light, contrast === 'dark' && styles.dark)}
            onClick={() => handleClick()}
            onMouseEnter={() => showTooltip(true)}
            onMouseLeave={() => {
              showTooltip(false);
              setTimeout(() => showTooltip(true), 50);
            }}>
            <Icon name="adjust"></Icon>
          </button>
          {tooltip && (
            <Tooltip Tooltip id="contrastData" type="light" place="top" effect="solid">
              Zmień kontrast
            </Tooltip>
          )}
        </div>
      }
    </>
  );
};

export default ContrastChanger;
