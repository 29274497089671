import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isContrast: false,
  isScrolled: false,
};

const availabilitySlice = createSlice({
  name: 'availabilitys',
  initialState,
  reducers: {
    saveContrast(state, action) {
      state.isContrast = action.payload;
    },
    saveScrolled(state, action) {
      state.isScrolled = action.payload;
    },
  },
});

export const { saveContrast, saveScrolled } = availabilitySlice.actions;
export const results = state => state.availabilitys;
export default availabilitySlice.reducer;
