import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRefreshToken, getToken } from 'api/accessToken';

const initialState = {
  token: null,
  refreshToken: null,
  statusFetchToken: null,
  statusFetchRefreshToken: null,
  username: '',
};
export const fetchToken = createAsyncThunk('token/getToken', async tokenData => {
  const { data } = await getToken(tokenData);
  return data;
});

export const fetchRefreshToken = createAsyncThunk('token/refresh/getRefresh', async () => {
  const { data } = await getRefreshToken();
  return data;
});

const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  extraReducers: {
    [fetchToken.pending]: state => {
      state.statusFetchToken = 'loading';
    },
    [fetchToken.fulfilled]: (state, action) => {
      state.token = action.payload.access;
      state.statusFetchToken = 'success';
    },
    [fetchToken.rejected]: state => {
      state.statusFetchToken = 'failed';
    },
    [fetchRefreshToken.pending]: state => {
      state.statusfetchRefreshToken = 'loading';
    },
    [fetchRefreshToken.fulfilled]: (state, action) => {
      state.token = action.payload;
      state.statusfetchRefreshToken = 'success';
    },
    [fetchRefreshToken.rejected]: state => {
      state.statusfetchRefreshToken = 'failed';
    },
  },
  reducers: {
    clearToken(state) {
      state.token = null;
    },
  },
});

export const { clearToken } = credentialsSlice.actions;
export const results = state => state.credentials;
export default credentialsSlice.reducer;
