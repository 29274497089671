import classNames from 'classnames';

import logo from '../../../images/footer.png';
import styles from './styles.module.scss';

const Footer = () => {
  return (
    <div className={classNames(styles.footerContainer)}>
      <div className={styles.footerWrapper}>
        <div className={styles.rightSide}>
          <p>Wydawca: SSPMK</p>
          <p>Email: sspmk@vp.pl</p>
        </div>
        <div className={styles.leftSide}>
          <img className={styles.footerImage} src={logo} alt="logo" />
          <p>
            Biuletyn informacyjny powstał w zadania „Dobrze wiedzieć” w projekcie „Ślaskie NOWEFIO na lata 2021-2023
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
