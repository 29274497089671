import classNames from 'classnames';
import Icon from 'components/atoms/Icon';
import Tooltip from 'components/atoms/Tooltip';
import React, { useState } from 'react';

import styles from './styles.module.scss';

const FontSizeChanger = ({ setFontSize }) => {
  const [size, setSize] = useState(
    localStorage.getItem('fontSize') === 'small' || !localStorage.getItem('fontSize')
      ? 0
      : localStorage.getItem('fontSize') === 'medium'
      ? 1
      : 2
  );
  const [tooltip, showTooltip] = useState(true);

  const handleClick = () => {
    if (size + 1 === 3) {
      setSize(0);
    } else {
      setSize(prev => prev + 1);
    }
    if (size === 0) {
      setFontSize('medium');
      localStorage.setItem('fontSize', 'medium');
    } else if (size === 1) {
      setFontSize('big');
      localStorage.setItem('fontSize', 'big');
    } else if (size === 2) {
      setFontSize('small');
      localStorage.setItem('fontSize', 'small');
    }
  };
  return (
    <>
      {
        <div className={classNames(styles.fontSizeChangerWrapper)}>
          <button
            data-tip
            data-for="fontData"
            className={classNames(size === 0 && styles.small, size === 1 && styles.medium, size === 2 && styles.big)}
            onClick={() => handleClick()}
            onMouseEnter={() => showTooltip(true)}
            onMouseLeave={() => {
              showTooltip(false);
              setTimeout(() => showTooltip(true), 50);
            }}>
            <Icon name="text height"></Icon>
          </button>
          {tooltip && (
            <Tooltip Tooltip id="fontData" type="light" place="top" effect="solid">
              Powiększ czcionkę
            </Tooltip>
          )}
        </div>
      }
    </>
  );
};

export default FontSizeChanger;
