import Layout from 'components/layout/Layout';
import { Helmet } from 'react-helmet-async';

import styles from './styles.module.scss';

const HomePage = () => {
  return (
    <Layout isHomepage={true}>
      <div className={styles.homePageContainer}>
        <main className={styles.layoutWrapper}>
          <div className={styles.text}>
            <h1>Dobrze wiedzieć</h1>
            <p>miesięcznik</p>
            <p>biuletyn informacyjny strażaków emerytów</p>
            <p>wydawca:</p>
            <p>Stowarzyszenie Seniorów Pożarnictwa Miasta Katowice</p>
            <a target="_blank" rel="noreferrer" href="http://www.sspmk.pl/">
              www.sspmk.pl
            </a>
            <p>czegoś nie wiesz?</p>
            <span>
              skontaktuj się z nami: <p>sspmk@vp.pl</p>
            </span>
          </div>
          <Helmet>
            <title>Miesięcznik dobrze wiedzieć</title>
            <meta
              name="description"
              content={
                'Dobrze wiedzieć biuletyn informacyjny strażaków emerytów - Stowarzyszenie Seniorów Pożarnictwa Miasta Katowice'
              }
            />
            <link rel="canonical" href="/" />
          </Helmet>
        </main>
      </div>
    </Layout>
  );
};

export default HomePage;
