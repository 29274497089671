import ContrastChanger from 'components/molecules/contrastChanger';
import FontSizeChanger from 'components/molecules/fontSizeChanger';
import React from 'react';

import styles from './styles.module.scss';

const Availability = ({ setFontSize, fontSize }) => {
  return (
    <div className={styles.availabilityWrapper}>
      <FontSizeChanger setFontSize={setFontSize} fontSize={fontSize} />
      <ContrastChanger />
    </div>
  );
};

export default Availability;
